
import { Getter } from "vuex-class";
import { WorkStatus } from "@/store/modules/activity.store";
import { PublicOffice } from "@/store/modules/public-office.store";
import { Component, Vue } from "vue-property-decorator";
import UserImage from "@/components/general/user-image.vue";
import { TeamFunctions } from "@/store/modules/activity-account.store";
import { Publication, PublicationType } from "@/store/modules/publications.store";

@Component({
    components: {
        UserImage,
        FormSelect: () => import("@/components/forms/select.vue"),
        ProgressHouse: () => import("./progress-house.vue"),
        ActivityDetailPart: () => import("@/views/seller/parts/activity/activity-detail.vue"),
    },
})
export default class PageSellerPartActivityHeader extends Vue {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("public-office/viewing") currentOffice!: PublicOffice;
    @Getter("publications/all") publications!: Publication[];
    @Getter("auth/me") currentAccount!: CurrentAccount;

    selectedActivity: number | null = null;

    get activityOptions() {
        return this.currentAccount.activities?.map((a) => {
            return {
                label: a.name,
                value: a.id,
            };
        });
    }

    async mounted() {
        this.selectedActivity = Number(this.$route.params.id);
    }

    async handleActivityChanged(newValue: number) {
        if (newValue === Number(this.$route.params.id)) {
            return;
        }

        await this.$router.push({ name: "seller-timeline", params: { id: `${newValue}` } });

        window.location.reload();
    }

    get teamMembers() {
        if (!this.activity) {
            return [];
        }

        return this.activity.assigned_to;
    }

    get salesContact() {
        if (!this.teamMembers.length) {
            return null;
        }

        const salesContact = this.teamMembers.find((tm: any) => tm.function_title === TeamFunctions.buyer); // TODO: this used to be agent but changed to buyer. is it OK?
        if (!salesContact) {
            return this.teamMembers[0];
        }

        return salesContact;
    }

    get baseProgress() {
        let progress = 0;
        switch (this.activity.work_status) {
            case WorkStatus.startUp || WorkStatus.newDossier:
                progress = 1;
                break;
            case WorkStatus.published:
                progress = 2;
                break;
            case WorkStatus.firstOffer:
                progress = 3;
                break;
            case WorkStatus.sold:
                progress = 5;
                break;
        }

        return progress;
    }

    get statusName() {
        switch (this.baseProgress) {
            case 1:
                return "Opstart";
            case 2:
                return "Online";
            case 3:
                return "Eerste bod";
            case 4:
                return "In optie";
            case 5:
                return "Verkocht";
        }
    }

    get statusDescription() {
        switch (this.baseProgress) {
            case 1:
                return "Opstart";
            case 2:
                return "Gepubliceerd op de website.";
            case 3:
                return "Eerste bod";
            case 4:
                return "In optie";
            case 5:
                return "Verkocht";
        }
    }

    get websitePublication() {
        return this.publications.find((p) => p.type === PublicationType.website);
    }

    get canShare() {
        return navigator.share !== undefined;
    }

    async share() {
        if (!this.websitePublication) {
            return;
        }

        const shareData = {
            title: this.activity.name,
            text: this.activity.name,
            url: this.websitePublication.url,
        };

        navigator.share(shareData);
    }
}
